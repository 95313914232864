.modal-dialog{
    max-width: 40%;
}

.fringe-bg{
    background-color: rgba(186,18,18,0.05);
    img{
        opacity: 0.5;
    }
}
.declare-bg{
    background-color:rgba(74,173,232,0.1)
}