@mixin btn($name,
$bgcolor,
$fontcolor,
$border,
$radius,$filter) {
    body .btn-#{$name},
    body .btn-#{$name}:hover,
    body .btn-#{$name}:focus,
    body .btn-#{$name}:active,
    body .btn-#{$name}:not(:disabled):not(.disabled).active,
    body .btn-#{$name}:not(:disabled):not(.disabled):active,
    .show>.btn-#{$name}.dropdown-toggle,
    body .btn-#{$name}:not([href]):not([tabindex]) {
        background-color: $bgcolor;
        color: $fontcolor;
        font-size: 1.06em;
        border-radius: $radius;
        padding: 0.45em 1em;
        border: $border;
        box-shadow: none;
        filter:$filter;
        font-family: "Open-Sans-Regular";
    }
}
@include btn("primary", #DE5A35, #ffffff, 1px solid #DE5A35, 8px,drop-shadow(0px 3px 6px rgba(0,0,0,0.15)));
@include btn("secondary", #F5F5F6, #414042, 1px solid #F5F5F6, 8px,none);

button{
    background: none;
    border:0;
}