// here we will create our major fonts styles
// Open Sans


.OpenSansSemiBold {
    font-family: "Open-Sans-SemiBold" !important;
}
.OpenSansBold {
    font-family: "Open-Sans-Bold" !important;
}

.OpenSansLight {
    font-family: "Open-Sans-Light" !important;
}

.OpenSansRegular {
    font-family: "Open-Sans-Regular" !important;
}

.size-9 {
    font-size: 0.5625rem;
}

.size-11 {
    font-size: 0.6875rem;
}

.size-12 {
    font-size: 0.75rem;
}

.size-13 {
    font-size: 0.812rem;
}

.size-14 {
    font-size: 0.875rem;
}

.size-15 {
    font-size: 0.9375rem;
}

.size-16 {
    font-size: 1rem;
}
.size-17 {
    font-size: 1.1rem;
}
.size-18 {
    font-size: 1.125rem;
}

.size-20 {
    font-size: 1.25rem;
}

.size-22 {
    font-size: 1.375rem;
}

.size-24 {
    font-size: 1.5rem;
}

.size-26 {
    font-size: 1.625rem;
}

.size-28 {
    font-size: 1.75rem;
}

.size-30 {
    font-size: 1.875rem;
}

.size-33 {
    font-size: 2.06rem;
}

.size-34{
    font-size: 2.125rem;
}

.size-40 {
    font-size: 2.5rem;
}

.size-64 {
    font-size: 4rem;
}

.size-74 {
    font-size: 4.625rem;
}