
$fontcolors: "fff","2865AC","000","4D4F5C","414042";
$bgcolors: "fff","E6E7E8","414042","DADADB";
@mixin colorfont($color) {
    $color-values: unquote("#" + $color);
    .color-#{$color} {
        color: #{$color-values} !important;
    }
}

@mixin backgroundcolor($bgColor) {
    $bg-color-values: unquote("#" + $bgColor);
    .bg-#{$bgColor} {
        background-color: #{$bg-color-values} !important;
    }
}


@each $fontcolor in $fontcolors {
    @include colorfont($fontcolor);
}

@each $bgcolor in $bgcolors {
    @include backgroundcolor($bgcolor);
}