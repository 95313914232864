:root {
  --duration: .4s;
}



@media (min-width: 20em) {
  :root {
    font-size: calc(0.875rem + (1vw - 1.5rem) * 0.1786);
    min-height: 0vw;
  }
}

@media (min-width: 300em) {
  :root {
    font-size: 1.375rem;
  }
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Open-Sans-Regular';
  font-size: 1rem;
  color: #4D4F5C;

  p {

    text-align: left;
  }

}



.text-dec-none {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.color-fff-opacity {
  color: rgba(255, 255, 255, 0.64);
}

.brd-btm {
  border-bottom: 1px solid rgba(111, 112, 112, 0.2);
}

.z-index9 {
  z-index: 9;
}

.section-wrapper {
  min-width: 1024px;
}

.collapse:not(.show) {
  display: block;
}

.navbar-collapse {
  display: none;
}

.wrapper {
  // height: calc(100% - 14vmin);
  height: calc(100% - 6rem);
  overflow: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  min-width: 1024px;
  // justify-content: space-between;

}



/********scrollbar style *************/
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  padding: 1px;
  height: 6px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: rgba(188, 188, 188, 0.3);
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #b4b4b4;
}

.custom-scroll::-webkit-scrollbar-thumb:window-inactive {
  background: #b4b4b4;
}

/***** Form Field *********/

.label-float {
  position: relative;

  input,
  textarea {
    border: 1px solid #e6e7e8;
    border-radius: 3px;
    outline: none;
    color: rgba(44, 44, 54, 0.6);
    // min-width: 20rem;
    padding: 1rem 0.5rem;
    width: 100%;
    font-size: 1rem;
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;

    // -webkit-appearance:none;
    &:focus,
    &:hover:focus {
      border: 1px solid #4bade9;
    }

    &:hover {
      border: 1px solid #414042;
    }

    &::placeholder {
      color: transparent;
    }

    &:required:invalid {
      border: 1px solid red;
    }

    &:required:invalid+label {
      color: red;

    }

    &:focus:required:invalid {
      border: 1px solid red;
    }

    &:required:invalid+label:before {
      content: '*';
    }

    &:focus+label,
    &:not(:placeholder-shown)+label {
      font-size: 1rem;
      top: -1.2rem;
      color: rgba(44, 44, 54, 0.8);
      // background: transparent;
    }

  }

  label {
    pointer-events: none;
    position: absolute;
    top: calc(50% - .9rem);
    font-size: 1rem;
    left: 9px;
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    background-color: white;
    padding: 0.2rem 0.5rem;
    box-sizing: border-box;
  }

  .icon {
    position: absolute;
    left: 1rem;
    top: 1rem;

    img {
      width: 1.1rem;
    }
  }
}

.dropdown-toggle:hover {
  border-color: #B3B3BA;
  font-size: 1rem;

  &::after {
    background: url(../assets/ico-arrow-down.svg) no-repeat;
    bordeR: 0;
    width: 15px;
    height: 10px;
    background-size: contain;
    vertical-align: middle;
  }

  &+.dropdown-menu {
    display: block;
  }

  .animation {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .show.slideDownIn {
    -webkit-animation-name: slideDownIn;
    animation-name: slideDownIn;
  }
}

.dropdown-menu:hover {
  display: block;
}

@-webkit-keyframes slideDownIn {
  0% {

    -webkit-transform: translateY(-20px);
  }

  100% {

    -webkit-transform: translateY(0);
  }

  0% {
    -webkit-transform: translateY(-20);
  }
}

@keyframes slideDownIn {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(-20px);
  }
}

.popover {
  background-color: #414042;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.16));

  // backdrop-filter: blur(29px) brightness(50%);
  .inner-popup-content {
    &>div {
      background: rgba(255, 255, 255, 0.07);
      border-radius: 4px;
    }
  }

  &.bs-popover-end>.popover-arrow::before {
    border-right-color: #414042;
  }

  &.bs-popover-end>.popover-arrow::after {
    border-right-color: #414042;
  }
}

.form-check-input {
  &:checked {
    background-color: #DE5A35;
    border-color: #DE5A35;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(222 90 53 / 25%);
  }
}

.alert-wrapper {
  position: fixed;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  z-index: 99;
  left: 0;

  &.close {
    display: none;
  }
}

.alert {
  // position: absolute;
  // bottom: 18.6vmin;
  width: 100%;
  z-index: 900;
  left: 0;
}

.alert p {
  background-color: transparent;
}

.checker {
  display: block;
  font-size: 8rem;
  height: .6em;
  width: 4em;
  box-sizing: content-box;
  padding: 0.4rem;
  border-radius: 8px;
  transition: transform var(--duration) ease;
  cursor: pointer;
  position: relative;

  &.yes-no {
    width: 1.2em;
    height: 0.3em;

    .check-bg {
      filter: none;
      border-radius: 34px;
    }

    .checkmark {
      svg {
        height: 0.3em;
        width: 0.6em;
        border-radius: 39px;
      }
    }

    .text-toggle {
      height: 0.3em;
    }

    .checkbox {
      &:checked {
        ~.checkmark {
          transform: translate(.8em);

          svg {
            //   background: #77c44c;
            transform: translate(-.2em);
            //   path { stroke-dashoffset: 0; }
          }
        }
      }
    }

  }

  &.flight {
    width: 2.8em;
    height: 0.4em;

    .checkmark {
      svg {
        // height: 0.3em;
        width: 1.4em;
        height: 0.4em;
        // border-radius: 39px;
      }
    }

    .text-toggle {
      height: 0.4em;
      transition: all 0.3s ease-in;

    }

    .checkbox {
      &:checked {
        ~.checkmark {
          transform: translate(1.55em);

          svg {
            //   background: #77c44c;
            transform: translate(-0.2em);
            //   path { stroke-dashoffset: 0; }
          }
        }
      }
    }
  }

  .checkmark {
    width: 1em;
    height: .6em;
    transition: transform var(--duration) ease;
    transform: translateX(-.4em);
    position: relative;
    z-index: 5;

    svg {
      display: block;
      // background: #d9eeff;
      background-color: #4AADE8;
      transform: translateX(.4em);
      border-radius: 8px;

      transition:
        background-color var(--duration) ease,
        transform calc(var(--duration) * 1.5) ease;
      height: .6em;
      width: 1.9em;
      // filter:drop-shadow(2px 0px 10px rgba(13,44,82,0.38));

      path {
        // opacity: 0;
        stroke-dasharray: 90 90;
        stroke-dashoffset: 90;
        transition: stroke-dashoffset calc(var(--duration) / 3) linear calc(var(--duration) / 3);
      }

    }
  }

  .text-toggle-span {
    color: #4D4F5C;
  }

  .text-toggle {
    div:nth-child(1) .text-toggle-span {
      font-family: 'Open-Sans-SemiBold';
      transition: all 0.3s ease-in;
    }
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    visibility: hidden;

    &:checked {
      ~.text-toggle {
        div:nth-child(1) .text-toggle-span {
          font-family: 'Open-Sans-Regular';
        }

        div:nth-child(2) .text-toggle-span {
          font-family: 'Open-Sans-SemiBold';
        }
      }

      ~.checkmark {
        transform: translate(2.8em);

        svg {
          //   background: #77c44c;
          transform: translate(-.7em);
          //   path { stroke-dashoffset: 0; }
        }
      }
    }
  }

  .text-toggle {
    position: absolute;
    z-index: 10;
    height: .6em;
  }

  .check-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: #F3F4F6;
    z-index: 2;
    // filter: drop-shadow(0px 1px 5px rgba(0,0,0,0.15));

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    }

    &:after {
      background: #F3F4F6;
    }


  }

  &:active {
    transform: scale(0.95);
    transition-duration: calc(var(--duration) / 2);

    .check-bg::before {
      transform: translateY(0) scale(0.95);
      opacity: 0.2;
    }
  }
}





.toggle {

  margin-top: 40px;

  input[type="checkbox"] {
    display: none;
  }

  label {
    color: #000;
    position: relative;
  }

  input[type="checkbox"]+label::before {
    content: " ";
    display: block;
    height: 23px;
    width: 45px;
    border-radius: 12px;
    position: absolute;
    top: -2px;
    left: -67px;
    background: rgba(147, 149, 149, 0.8);
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  input[type="checkbox"]+label::after {
    content: " ";
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    top: -6px;
    left: -75px;
    cursor: pointer;
    background: #fff;
    transition: all 0.3s ease-in;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
  }

  input[type="checkbox"]:checked+label::after {
    left: -44px;
    transition: all 0.3s ease-in;
    background: #DE5A35;
  }

  input[type="checkbox"]:checked+label::before {
    background-color: rgba(245, 205, 194, .8);
  }
}



.graphical-illustration {
  min-height: 32rem;
  overflow: hidden;
  min-width: 100vw;

  .path {
    position: absolute;
    right: -130px;
    bottom: 0;
  }

  .traveller {
    position: absolute;
    width: 107px;
  }

  .board {
    position: absolute;
    top: 2rem;
    right: 10rem;
    width: 21rem;

    img {
      width: 100%;
    }
  }

}

.legend-indicator {
  border-radius: 2px;
  width: 14px;
  height: 15px;
}

.Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  color: #000;
}

.Calendar__sectionWrapper {
  min-height: 28em;
}

.purposedropdown {
  min-width: 11em;
}

.business-color,
.Business {
  background-color: #4AADE8 !important;
  color: #fff !important;
}

.privatebusiness-color,
.PrivateBusiness {
  background-color: #4AADE8 !important;
  border-bottom: 8px solid #9DC9C1;
  color: #fff !important;
}

// .privatebusiness-color,.PublicHoliday{
//   background-color: #9DC9C1 !important;
//   border-bottom: 8px solid #4AADE8;
//   color: #fff !important;
// }
.private-color,
.Private {
  background-color: #9DC9C1 !important;
  color: #fff !important;
}

.rest-color,
.Rest {
  background-color: #EDA44C !important;
  color: #fff !important;
}

.travel-color,
.Travel {
  background-color: #BA126B !important;
  color: #fff !important;
}

.unaccounted-color {
  background-color: #312F2F;
  color: #fff !important;
}


.summary-block {
  &:before {
    content: '';
    width: 10px;
    height: 100%;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px 0 0 4px;
  }

  &.business {
    border: 1px solid #4AADE8;
    border-radius: 4px;

    &:before {
      background: #4AADE8;
      min-width: 1rem;
    }
  }

  &.travel {
    border: 1px solid #BA126B;
    border-radius: 4px;
    margin-right: 1.5rem;

    &:before {
      background: #BA126B;
      min-width: 1rem;
    }
  }

  &.rest {
    border: 1px solid #EDA44C;
    border-radius: 4px;

    &:before {
      background: #EDA44C;
      min-width: 1rem;
    }
  }

  &.private {
    border: 1px solid #9DC9C1;
    border-radius: 4px;

    &:before {
      background: #9DC9C1;
      min-width: 1rem;
    }
  }
}

.disable_true,
.label-float input:read-only {
  pointer-events: none;
  background-color: lightgrey !important;
}

.label-float input:read-only+label {
  top: -2.1rem;
}

.right-tabs {
  // position: absolute;
  // right: 0;
  // border-bottom: 0;
  // min-height: 7.2rem;



  min-height: 5.25rem;
  position: fixed;
  right: 0;
  z-index: 100;

  // z-index:99;
  .nav-item {
    border-bottom: 0;
    display: inline-block;

    .nav-link {
      background: #4C4B4D;
      padding: 0.7rem 4rem 0.5rem 1.5rem;
      text-align: left;
      border: 0;
      font-size: 1.6rem;
      height: 100%;
      color: #A0A0A1;
      border-bottom: 1rem solid transparent;

      &.active {
        color: #fff;
        font-size: 1.6rem;
        border-bottom: .7rem solid #FFEC62;
        background-color: #585758;
      }
    }

    //    .right-tabs-nav-link[class="active"] {
    //     color: #fff;
    //     font-size: 2.2rem;
    //     border-bottom: .7rem solid #FFEC62;
    //     background-color: #585758;
    //    }
  }
}


.Calendar {
  background: #E6E7E8;
  width: 100%;
  z-index: 10;
  max-width: 90vw;
  min-height: 26.7em;
  font-size: 0.72em;
  pointer-events: none;
}

.Calendar__weekDay {
  color: #000;
}

.Calendar__day {
  border-radius: 5px;
  margin: 0.1rem;

}

.Calendar__day:hover {
  pointer-events: none;
}

.Calendar__day.-selected {
  background-color: transparent;
  border-radius: 5px;
  color: #000;
}

#dashaboard-view {
  .nav-tabs {
    margin-top: -2.6rem;
    bordeR: 0;
    min-height: 4rem;
    width: 500px;

    .nav-item {
      background: #fff;
      border-top-left-radius: 4px;
      border: 0;
      border-top-right-radius: 4px;
      min-width: 14rem;
      font-family: 'Open-Sans-Regular';
      min-height: 3rem;
      padding: 0.2rem;
      position: relative;

      // &:after{
      //   content:'';
      //   width:15px;
      //   height: 15px;
      //   // background: url(../assets/completed-icon.svg) no-repeat ;
      //   background-size: contain;
      //   display: inline-flex;
      //   position: absolute;
      //   right:0.8rem;
      //   top:0.8rem;
      // }
      &:nth-child(2) {

        margin-left: 0.5rem;
        // &:after{
        //   // background: url(../assets/pending-icon.svg) no-repeat ;
        //   background-size: contain;
        // }
      }

      .nav-link {
        border: 0;
        color: #414042;
        width: 100%;
        text-align: left;
        background: #E6E7E8;

        &:hover {
          background: #eee;
        }

        &.active {
          font-family: 'Open-Sans-SemiBold';
          // border-bottom:3px solid #ffe600;
          // background: #666;
          background-color: #4AADE8;
          color: #fff;
        }


      }
    }
  }
}

.MuiTable-root {
  width: calc(100% - 21rem) !important;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 5rem 10rem;
  // table-layout: fixed !important;
}

.MuiTable-root .MuiTableBody-root {
  // height: 20rem;
  // overflow: auto;
  // display: block;
  // width: 100%;
}

.MuiTable-root .MuiTableRow-root {
  // display: table;
  // width: 100%;
}

.MuiTableCell-head {
  background-color: #f5f5f5 !important;
}

.MuiTableCell-paddingNone:last-child {
  padding: 10px 30px 10px 15px !important;
}

.MuiTableFooter-root {
  display: grid;
  background: #fff;
  position: absolute;
  top: 0;
  min-height: 10rem;
  width: 21rem;
  right: 10rem;
}

div[class*="-option"]:before {
  content: '';
}

.purpose-select div[class*="-option"]:first-child:before {
  width: 14px;
  height: 15px;
  border-radius: 2px;
  display: inline-flex;
  margin: 0 10px 0 0;
  background-color: #4AADE8;
}

.purpose-select div[class*="-option"]:nth-child(2):before {
  width: 14px;
  height: 15px;
  border-radius: 2px;
  display: inline-flex;
  margin: 0 10px 0 0;
  background-color: #9DC9C1;
}

.purpose-select.international div[class*="-option"]:nth-child(3):before {
  width: 14px;
  height: 15px;
  border-radius: 2px;
  display: inline-flex;
  margin: 0 10px 0 0;
  background-color: #EDA44C;
}

/* Target a different index for domestic travel, because some dropdown options would be hidden. */
.purpose-select.domestic div[class*="-option"]:nth-child(3):before,
.purpose-select.international div[class*="-option"]:nth-child(4):before {
  width: 14px;
  height: 15px;
  border-radius: 2px;
  display: inline-flex;
  margin: 0 10px 0 0;
  background-color: #BA126B;
}

.activity-select div[class*="-option"] {
  width: 0%;
  height: 0%;
}


.calendar-column {
  max-width: 500px;
}

.calendar-view {
  min-width: 230px
}

.view-form-calendar-wrap .view-form-calendar {
  min-width: 21em;
}

.disable_true,
.label-float textarea:read-only {
  background-color: #d3d3d3 !important;
  pointer-events: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1
}