.app-navbar{
        min-height: 8vmin;
        .nav-dropdown-toggle{
            font-size: 1.125rem !important;
            &::after {
                display: none;
                // display: inline-block;
                margin-left: 0.9rem;
                vertical-align: -0.32rem;
                content: "";
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
                background: url(../../public/assets/caret.svg) no-repeat;
                width: 12px;
                height: 7px;
                background-size: contain;
                border: 0;
            }
        }
    }

  