.right-tabs{
    .right-tabs-nav-tabs{
      border-bottom:0;
       .right-tabs-nav-link{
        background: #4C4B4D;
        padding: 0.7rem 4rem 0.5rem 1.5rem;
        text-align: left;
        border:0;
        color:#A0A0A1;
        border-bottom: 1rem solid transparent;
        &:global(.active){
          color: #fff;
          font-size: 2.2rem;
          border-bottom: .7rem solid #FFEC62;
          background-color: #585758;
         }
       }
    //    .right-tabs-nav-link[class="active"] {
    //     color: #fff;
    //     font-size: 2.2rem;
    //     border-bottom: .7rem solid #FFEC62;
    //     background-color: #585758;
    //    }
    }
  }

  .form-header{
    // border-radius: 10px 10px 0 0;
    // min-height: 15rem;


    position: fixed;
    width: 100%;
    z-index: 100;
  }
