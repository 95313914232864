.loader {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 0 black;
  box-shadow: 0 0 black;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.loader>div {
  width: 300px;
  height: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.loader .dot-loader {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #ffe600;
  position: relative;
  -webkit-animation: 1.2s grow ease-in-out infinite;
  animation: 1.2s grow ease-in-out infinite;
}

.loader .dot-loader--2 {
  -webkit-animation: 1.2s grow ease-in-out infinite 0.15555s;
  animation: 1.2s grow ease-in-out infinite 0.15555s;
  margin: 0 20px;
}

.loader .dot-loader--3 {
  -webkit-animation: 1.2s grow ease-in-out infinite 0.3s;
  animation: 1.2s grow ease-in-out infinite 0.3s;
}

.loaded .loader {
  display: none;
}

@-webkit-keyframes grow {

  0%,
  40%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes grow {

  0%,
  40%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes podAnimate {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes podAnimate {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.view-form-calendar .Calendar__monthArrowWrapper {
  display: none;
}

.text-align-left {
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 3em !important;
}

.tripMargins {
  margin-left: 1rem;
  margin-bottom: 0;
  margin-right: 2.5rem;
}

.marginLeft {
  margin-left: 1.5rem;
}

.tripClass {
  text-align:center;
  margin-top: 1.5rem;
  margin-left: 1.3rem;
  background: #333;
  color: #fff;
  font-size: 1.5rem;
  font-style: OpenSansSemiBold;
  max-width: 93%;
}

.fringeClass {
  width: 100%;
  text-align: center;


}

.fringeClass span {
  color: #ba3f3f
}

.locationImage {
  width: 15px;
  height: 15px;
}

.businessTooltipColor {
  background-color: #4387b0 !important;
}

.privateTooltipColor {
  background-color: #799f98 !important;
}

.restTooltipColor {
  background-color: #9f7038 !important;
}

.travelTooltipColor {
  background-color: #8c1353 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.Calendar {
  box-shadow: none !important;
}

// .Calendar .Business{

//   color: #fff!important;
//   }

div[class*="control"]{
  border-radius: 0px !important;
}
div[class*="-option"]:active,
div[class*="-option"] {
  color: black;
  background-color: transparent;
}

div[title*='View Form'] {
  display: none;
}

.z-999 {
  z-index: 9999;
}

.margintop-wrapper {
  margin-top: 4rem !important;
}

.marginLeftWrapper {
  margin-left: 0.5rem !important;
}

.dashboardView .MuiPaper-elevation2 {
  box-shadow: none !important;
}

.MuiTooltip-tooltip {
  color: #fff;
  padding: 4px 8px;
  font-size: 1rem !important;
  max-width: 300px;
  word-wrap: break-word;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
}

.leave-dropdown {
  min-width: 11em;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #2196f3;
  color: white;
}

.navbar-toggler-icon {
  display: none;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.descriptionClass {
  height: 3.1em;
  min-height: 3.1em;
  margin-top:5px;
  overflow: hidden;
}

.react-datepicker__year-option:first-of-type{
  display: none !important;
}
.selectionDate .react-datepicker__year-read-view--down-arrow{
display: none !important;
}
.selectionDate .react-datepicker__year-read-view {
  pointer-events: none;
}
.react-datepicker__year-read-view--down-arrow :hover{
  border-color: #fff !important;
}
.travelDiaryClass {
  margin: 0 auto;
  margin-top: 2rem;
}
.text-align-center{
  text-align: center;
}