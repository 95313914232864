  /*animations*/


  .animated { 
    -webkit-animation-duration: 1s; 
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
  } 
  
  /* Added by Andy Meetan */
  .delay-250 {
    -webkit-animation-delay:0.25s;
    -moz-animation-delay:0.25s;
    -o-animation-delay:0.25s;
    animation-delay:0.25s;
  }
  .delay-500 {
    -webkit-animation-delay:0.5s;
    -moz-animation-delay:0.5s;
    -o-animation-delay:0.5s;
    animation-delay:0.5s;
  }
  .delay-750 {
    -webkit-animation-delay:0.75s;
    -moz-animation-delay:0.75s;
    -o-animation-delay:0.75s;
    animation-delay:0.75s;
  }
  .delay-1000 {
    -webkit-animation-delay:1.0s;
    -moz-animation-delay:1.0s;
    -o-animation-delay:1.0s;
    animation-delay:1.0s;
  }
  .delay-1250 {
    -webkit-animation-delay:1.25s;
    -moz-animation-delay:1.25s;
    -o-animation-delay:1.25s;
    animation-delay:1.25s;
  }
  .delay-1500 {
    -webkit-animation-delay:1.5s;
    -moz-animation-delay:1.5s;
    -o-animation-delay:1.5s;
    animation-delay:1.5s;
  }
  .delay-1750 {
    -webkit-animation-delay:1.75s;
    -moz-animation-delay:1.75s;
    -o-animation-delay:1.75s;
    animation-delay:1.75s;
  }
  .delay-2000 {
    -webkit-animation-delay:2.0s;
    -moz-animation-delay:2.0s;
    -o-animation-delay:2.0s;
    animation-delay:2.0s;
  }
  .delay-2250 {
  
    -webkit-animation-delay:2.25s;
    -moz-animation-delay:2.25s;
    -o-animation-delay:2.25s;
    animation-delay:2.25s;  
    }
  .delay-2500 {
    -webkit-animation-delay:2.5s;
    -moz-animation-delay:2.5s;
    -o-animation-delay:2.5s;
    animation-delay:2.5s;
  }
  
  
  /**********
  * Fade In *
  **********/ 
  @-webkit-keyframes fadeIn { 
    0% {opacity: 0;} 
    100% {opacity: 1;
        display:block;} 
  } 
  @keyframes fadeIn { 
    0% {opacity: 0;} 
    100% {opacity: 1;
        display:block;} 
  }
  .fadeIn{
    opacity:0;
  }
  .fadeIn.go { 
    -webkit-animation-name: fadeIn; 
    animation-name: fadeIn; 
  }
  
  /**********
  * Grow in *
  ***********/
  
  @-webkit-keyframes growIn { 
    0% { 
        -webkit-transform: scale(0.2); 
        opacity:0;
    } 
    50% { 
        -webkit-transform: scale(1.2); 
        
    } 
    100% { 
        -webkit-transform: scale(1); 
        opacity:1;
    } 
  } 
  @keyframes growIn { 
    0% { 
        transform: scale(0.2); 
        opacity:0;
    } 
    50% { 
        transform: scale(1.2); 
        
    } 
    100% { 
        transform: scale(1); 
        opacity:1;
    } 
  } 
  .growIn { 
  
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity:0;
  }
  .growIn.go{
    -webkit-animation-name: growIn; 
    animation-name: growIn; 
  }
  
  
  /*************
  * FadeInLeft *
  *************/
  
  @-webkit-keyframes fadeInLeft { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateX(-400px); 
    } 
    50%{
       opacity: 0.3; 
    }
    100% { 
        opacity: 1; 
        -webkit-transform: translateX(0); 
    } 
  } 
  @keyframes fadeInLeft { 
    0% { 
        opacity: 0; 
        transform: translateX(-400px); 
    } 
    50%{
       opacity: 0.3; 
    }
    100% { 
        opacity: 1; 
        transform: translateX(0); 
    } 
  } 
  .fadeInLeft{ 
    opacity: 0; 
    -webkit-transform: translateX(-400px); 
    transform: translateX(-400px);
  }
  .fadeInLeft.go { 
    -webkit-animation-name: fadeInLeft; 
    animation-name: fadeInLeft; 
  }
  
  
  /*************
  * FadeInRight *
  *************/
  
  @-webkit-keyframes fadeInRight { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateX(400px); 
    } 
    50%{
       opacity: 0.3; 
    }
    100% { 
        opacity: 1; 
        -webkit-transform: translateX(0); 
    } 
  } 
  @keyframes fadeInRight { 
    0% { 
        opacity: 0; 
        transform: translateX(400px); 
    } 
    50%{
       opacity: 0.3; 
    }
    100% { 
        opacity: 1; 
        transform: translateX(0); 
    } 
  } 
  .fadeInRight{ 
    opacity: 0; 
    -webkit-transform: translateX(400px); 
    transform: translateX(400px);
  }
  .fadeInRight.go { 
    -webkit-animation-name: fadeInRight; 
    animation-name: fadeInRight; 
  }
  
  /*************
  * FadeInUp *
  *************/
  
  @-webkit-keyframes fadeInUp { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateY(400px); 
    } 
    50%{
       opacity: 0.3; 
    }
    100% { 
        opacity: 1; 
        -webkit-transform: translateY(0); 
    } 
  } 
  @keyframes fadeInUp { 
    0% { 
        opacity: 0; 
        transform: translateY(400px); 
    } 
    50%{
       opacity: 0.3; 
    }
    100% { 
        opacity: 1; 
        transform: translateY(0); 
    } 
  } 
  .fadeInUp{ 
    opacity: 0; 
    -webkit-transform: translateY(400px); 
    transform: translateY(400px);
  }
  .fadeInUp.go { 
    -webkit-animation-name: fadeInUp; 
    animation-name: fadeInUp; 
  }
  
  /*************
  * FadeInDown *
  *************/
  
  @-webkit-keyframes fadeInDown { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateY(-400px); 
    } 
    50%{
       opacity: 0.3; 
    }
    100% { 
        opacity: 1; 
        -webkit-transform: translateY(0); 
    } 
  } 
  @keyframes fadeInDown { 
    0% { 
        opacity: 0; 
        transform: translateY(-400px); 
    } 
    50%{
       opacity: 0.3; 
    }
    100% { 
        opacity: 1; 
        transform: translateY(0); 
    } 
  } 
  .fadeInDown{ 
    opacity: 0; 
    -webkit-transform: translateY(-400px); 
    transform: translateY(-400px);
  }
  .fadeInDown.go { 
    -webkit-animation-name: fadeInDown; 
    animation-name: fadeInDown; 
  }
  
  /*****************
  * Short Animations
  *******************/
  
  /*********************
  * fadeInUpShort
  *********************/
  @-webkit-keyframes fadeInUpShort { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateY(120px); 
    } 
    100% { 
        opacity: 1; 
        -webkit-transform: translateY(0); 
    } 
  } 
  
  @keyframes fadeInUpShort { 
    0% { 
        opacity: 0; 
        transform: translateY(120px); 
    } 
    100% { 
        opacity: 1; 
        transform: translateY(0); 
    } 
  } 
  .fadeInUpShort{
    opacity: 0; 
    -webkit-transform: translateY(120px); 
    transform: translateY(120px); 
  }
  .fadeInUpShort.go { 
    -webkit-animation-name: fadeInUpShort; 
    animation-name: fadeInUpShort; 
  }
  
  /*********************
  * fadeInDownShort
  *********************/
  @-webkit-keyframes fadeInDownShort { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateY(-20px); 
    } 
    100% { 
        opacity: 1; 
        -webkit-transform: translateY(0); 
    } 
  } 
  
  @keyframes fadeInDownShort { 
    0% { 
        opacity: 0; 
        transform: translateY(-20px); 
    } 
    100% { 
        opacity: 1; 
        transform: translateY(0); 
    } 
  } 
  .fadeInDownShort{
    opacity: 0; 
    -webkit-transform: translateY(-20px); 
    transform: translateY(-20px); 
  }
  .fadeInDownShort.go { 
    -webkit-animation-name: fadeInDownShort; 
    animation-name: fadeInDownShort; 
  }
  
  /*********************
  * fadeInRightShort 
  *********************/
  @-webkit-keyframes fadeInRightShort { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateX(20px); 
    } 
    100% { 
        opacity: 1; 
        -webkit-transform: translateX(0); 
    } 
  } 
  @keyframes fadeInRightShort { 
    0% { 
        opacity: 0; 
        transform: translateX(20px); 
    } 
    100% { 
        opacity: 1; 
        transform: translateX(0); 
    } 
  } 
  .fadeInRightShort { 
    opacity: 0; 
    -webkit-transform: translateX(20px);  
    transform: translateX(20px); 
  }
  .fadeInRightShort.go { 
    -webkit-animation-name: fadeInRightShort; 
    animation-name: fadeInRightShort; 
  }
  
  /*********************
  * fadeInLeftShort 
  *********************/
  @-webkit-keyframes fadeInLeftShort { 
    0% { 
        opacity: 0; 
        -webkit-transform: translateX(-20px); 
    } 
    100% { 
        opacity: 1; 
        -webkit-transform: translateX(0); 
    } 
  } 
  @keyframes fadeInLeftShort { 
    0% { 
        opacity: 0; 
        transform: translateX(-20px); 
    } 
    100% { 
        opacity: 1; 
        transform: translateX(0); 
    } 
  } 
  .fadeInLeftShort { 
    opacity: 0; 
    -webkit-transform: translateX(-20px);  
    transform: translateX(-20px); 
  }
  .fadeInLeftShort.go { 
    -webkit-animation-name: fadeInLeftShort; 
    animation-name: fadeInLeftShort; 
  }