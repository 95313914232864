.view-form-wrapper{
    border-radius: 10px;
    position: relative;
    top: 6rem;
    // margin-bottom: -6rem !important;
    width: calc(100% - 3rem);
    left:1.5rem;
}

.tableagenda{
    .label-float input{
        min-width: auto;
    }
    /* Set header to stick to the top of the container. */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ and Edge */
    user-select: none; /* Standard syntax */
    margin:0 auto;
    thead{
        th{
            color: #4D4F5C;
            background: #E6E7E8;
            position: sticky;
            top: 0;
            z-index: 9;
            height:30px;
            text-align: left;
            font-size: 1rem;
            border:0;
            vertical-align: middle;
            color:#4D4F5C;
        }
    }
    tbody{
        border-collapse: separate;
        tr{ 
            // cursor: pointer;
            cursor:all-scroll;
            
            td{
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                // user-drag: none;
                vertical-align: middle;
                position: relative;
                border:0;
                color:#4D4F5C;
                background:#fff;
                font-size: 0.85rem;
                padding-top:1.2rem;
                cursor:all-scroll;
                img{
                    -webkit-user-drag: none;
                    -khtml-user-drag: none;
                    -moz-user-drag: none;
                    // -o-user-drag: none;
                    // user-drag: none;
                }
            }
            .show-btns{
                display: none;
                cursor: pointer;
                &:hover{
                    display: flex;
                    position: absolute;
                    flex-direction: row;
                    top: 0;
                    right: 0.6rem;
                    background: #F5F5F6;
                    align-items: center;
                }
                img{
                    width: 3rem;
                }
                
            }
            .view-more{
                background-color: #F5F5F6;
                padding: 0.8rem 1rem;
                border-radius: 2px;
            }
            .view-more:hover{
                cursor: pointer;
               
                & + .show-btns,.show-btns:hover{
                    display: flex;
                    position: absolute;
                    flex-direction: row;
                    cursor: pointer;
                    top: 1rem;
                    right: 0.6rem;
                    background: #F5F5F6;
                    align-items: center;
                    .btn{
                        cursor: pointer;
                    }
            
                }
            }
      &:first-child{
        .btn_row_delete,.divider{
            display: none;
      }
     }
    
    }
    }

    
    
    .label-float{
        input, textarea{
            min-width: 14rem;
            max-width: 14rem;
        }
    } 




tr.alt td {
    background-color: #ecf6fc;
    padding-top: 5px;
    padding-bottom: 5px;
}

td {
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
}


}



.bg-EDF7FD{
    background-color: #EDF7FD;
}