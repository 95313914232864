

  .form-header{
    // border-radius: 10px 10px 0 0;
    min-height: 15rem;
  }

  .form-container{
   
    border-radius: 10px;
    position: relative;
    // top: -9.3rem;
    // margin-bottom: -6rem !important;
    top: 7rem;
    width: calc(100% - 5rem);
    left: 1rem;
  }
  
 .form{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
 }
.tableWrap{
    max-height: 40vh;
    margin-bottom: 1rem;
    overflow-x: scroll;
}
.table > :not(:first-child){
    border:0;
} 
.dragging{
    position: absolute !important;
}

.draggable .drag-icon {
    cursor: move;
    user-select: none;
}
.tableagenda{
    .label-float input{
        min-width: auto;
    }
    /* Set header to stick to the top of the container. */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ and Edge */
    user-select: none; /* Standard syntax */
    margin:0 auto;
    thead{
        th{
            color: #4D4F5C;
            background: #E6E7E8;
            position: sticky;
            top: 0;
            z-index: 9;
            height:30px;
            text-align: left;
            font-size: 1rem;
            border:0;
            vertical-align: middle;
            color:#4D4F5C;
        }
    }
    tbody{
        border-collapse: separate;
        tr{ 
            // cursor: pointer;
            cursor:all-scroll;
            
            td{
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                // user-drag: none;
                vertical-align: middle;
                position: relative;
                border:0;
                color:#4D4F5C;
                background:#fff;
                font-size: 1rem;
                padding-top:1.2rem;
                cursor:all-scroll;
                img{
                    -webkit-user-drag: none;
                    -khtml-user-drag: none;
                    -moz-user-drag: none;
                    // -o-user-drag: none;
                    // user-drag: none;
                }
            }
            .show-btns{
                display: block;
                cursor: pointer;
                background: #F5F5F6;
                // &:hover{
                //     display: flex;
                //     position: absolute;
                //     flex-direction: row;
                //     top: 1rem;
                //     right: 0.6rem;
                //     background: #F5F5F6;
                //     align-items: center;
                // }
                img{
                    width: 3rem;
                }
                
            }
            .view-more{
                background-color: #F5F5F6;
                padding: 0.8rem 1rem;
                border-radius: 2px;
            }
            // .view-more:hover{
            //     cursor: pointer;
               
            //     & + .show-btns,.show-btns:hover{
            //         display: flex;
            //         position: absolute;
            //         flex-direction: row;
            //         cursor: pointer;
            //         top: 1rem !important;
            //         right: 0.6rem;
            //         background: #F5F5F6;
            //         align-items: center;
            //         .btn{
            //             cursor: pointer;
            //         }
            
            //     }
            // }
      &:first-child{
        .btn_row_delete,.divider{
            // display: none;
      }
     }
    
    }
    }

    
    
    .label-float{
        input, textarea{
            min-width: 14rem;
            max-width: 14rem;
        }
    } 




tr.alt td {
    background-color: #ecf6fc;
    padding-top: 5px;
    padding-bottom: 5px;
}

td {
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
}
.drag-icon{
    position: relative;
    z-index: 0;
    &:before{
        content:'';
        background: url(../../../public/assets/drag-ico.svg) no-repeat;
        width:12px;
        height:17px;
        display: flex;

    }
}
}
.travel-agenda{
    // height: 37vmin;
    flex-wrap: wrap;
    position: relative;
    z-index: 99;
}
.travel-agenda-wrap{
    // max-height: 32rem;
    // overflow: auto;
}
:global([id*='table_disable_true']) div[class*="-control"]{
    pointer-events: none;
    background-color: lightgrey !important;
}
:global(div[class*="-menu"] div[class*="-MenuList"] div){
    
        background-color: #fff;
        color: #000;
        text-align: left;
        display: flex;
        align-items: center;
}
:global(div[class*="-singleValue"]){
    display: flex;
    align-items: center;
}
:global(div[class*="-menu"] div[class*="-MenuList"] div[class*="-option"]:before),
:global( div[class*="-container"] div[class*="-singleValue"]:before)
{
    content:'';
}
:global(div[class*="-menu"]  div[class*="-MenuList"]){
    text-align: left;
}
:global(div[id*="_purpose"] div[class*="-menu"] div[class*="-MenuList"] div[class*="-option"]:first-child:before),
:global( .Business div[class*="-singleValue"]:before),
:global(.react-calendar__tile.business-color){
    border-radius: 2px;
    width: 14px;
    height: 15px;
    margin: 0 10px 0 0;
    display: inline-flex;
    background-color: #4AADE8;
}
:global(div[id*="_purpose"] div[class*="-MenuList"] div:nth-child(2):before),

:global( .Private div[class*="-singleValue"]:before){
    border-radius: 2px;
    width: 14px;
    height: 15px;
    margin: 0 10px 0 0;
    display: inline-flex;
    background-color: #9DC9C1;
}
:global(div[id*="_purpose"] div[class*="-MenuList"] div:nth-child(3):before),

:global( .Rest div[class*="-singleValue"]:before){
    border-radius: 2px;
    width: 14px;
    height: 15px;
    margin: 0 10px 0 0;
    display: inline-flex;
    background-color:  #EDA44C;
}
:global(div[id*="_purpose"] div[class*="-MenuList"] div:nth-child(4):before),

:global( .Travel div[class*="-singleValue"]:before){
    border-radius: 2px;
    width: 14px;
    height: 15px;
    margin: 0 10px 0 0;
    display: inline-flex;
    background-color: #BA126B;
}
:global(div[id*="_purpose"] div[class*="-MenuList"] div:nth-child(5):before),

:global( .Others div[class*="-singleValue"]:before),
:global(.react-calendar__tile.unaccounted-color){
    background-color: #312F2F;
}

:global(div[class*="-control"]){
    text-align: left;
    // min-width: 17em;
}

.req_true {
    border: 1px solid red !important;
}
.delete-all{
    background: #fff;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.16));
    border: 0;
}

.disable_true{
    pointer-events: none;
    background-color: lightgrey !important;
}
.SED-row button{
    border: 0;
    background-color: #fff;
    margin: 0.3rem;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.16));
    padding: 0.3rem;
}

.alert-hidden {
    opacity: 0;
    transition: all 250ms linear 2s; // <- the last value defines transition-delay
  }
  :global(.Calendar__day){
      margin: .2rem;
      min-width: 20px;
  }
  :global(.Calendar__day.default-color){  
    background-color: #B8B8B8 !important;
    color:#fff ;
    border-radius: 0;
}
:global(.Calendar__day.Business){
    background-color: #4AADE8;
    color:#fff;

}

