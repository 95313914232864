.dashboard-wrapper{
    border-radius: 10px;
    position: relative;
    top: 8rem;
    margin-bottom:2rem;
    // margin-bottom: -6rem !important;
    width: calc(100% - 6rem);
    border-top-left-radius: 0;
    &:global(.nav-tabs){
        margin-top:-2.6rem;
        .nav-item{
            background: #fff;
            margin-left: 0.5rem;
            border-radius: 4px;
            min-width: 9rem;
            font-family: 'OpenSans-Regular';
             color:#414042;
        }
    }
}