.react-datepicker{
    display: flex;
    flex-direction: row;
    filter: drop-shadow(2px 4px 6px rgba(65,64,66,0.22));
    border-color: #ddd;
    padding: 2rem 1rem 1rem 1rem;
}
.react-datepicker-popper{
    z-index: 100;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border-bottom-color:#c4c4cd;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    font-family: "Open-Sans-SemiBold";
    color: #43425d;
}
.react-datepicker__navigation-icon::before{
    top: 16px;
    // border-color:#A3A6B4;
    border-color:white !important;
}
.react-datepicker__month-container{
    margin-right:1rem;
    .react-datepicker__header{
        background:#fff;
        border-bottom-color: #E9E9F0;
    }
}

.react-datepicker__day-names{
    border-bottom: 1px solid #E9E9F0;
    padding-top: 0.6rem;
    .react-datepicker__day-name{
        color: #A3A6B4;
        font-family: "Open-Sans-SemiBold";
    }
    .react-datepicker__day{
        color: #43425D;
        font-family: 'Open-Sans-Regular';
    }
}
.label-float label.date-range{
    margin-left: 1.5rem;
    top: calc(50% - 9px);
    padding: 3px 5px 2px;
}
.label-float .focus-date-range{
    font-size: 1rem;
    top: -30px;
    color: rgba(44, 44, 54, 0.8);
}
.react-datepicker__input-container input{
    padding-left:3rem;
    padding-right:2rem;
    width: fit-content;
    &.disable_true{
        pointer-events: none;
        background-color: lightgrey;
    }
    
}

.react-datepicker__day--in-range{
    // background-color: rgba(87,169,249,0.1);
    background-color: #bbdefb;
    color: #43425D;
    margin: 0;
    width: 2rem;
    border-radius: 0;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
    // background-color: rgba(87,169,249,0.1);
    background-color: #bbdefb;
    color: #43425D;
}
.react-datepicker__day--range-start, .react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-start,.react-datepicker__day--selecting-range-start{
    background-color: #2196f3!important;
    border-radius: 2px;
    color: #fff !important;
        // background-color: #57A9F9 !important;
        // border-radius:2px;
        // color:#fff !important;
}
.react-datepicker__header__dropdown {
    // background: rgba(87,169,249,0.1);
    background: #DE5A35;
    position: fixed;
    width: 100%;
    z-index: 9;
    left: -0.1rem;
    color:#fff;
    top: 0rem;
    padding: 0.3rem;
    font-size: 1rem;
    font-family: 'Open-Sans-Bold';
}
.react-datepicker__navigation{
    top:-9px;
    z-index: 10;

}
.react-datepicker__year-read-view--down-arrow{
    top: 4px;
    border-width: 2px 2px 0 0;
    border-color: #fff !important;
    width: 7px;
    height: 7px;
}

.react-datepicker__year-dropdown {
    background-color: whitesmoke;
    position:absolute;
    scroll-behavior: 0;
}

.react-datepicker__year-dropdown .selected{
    cursor:pointer;
}

.react-datepicker__year-option {
    color: black;
    padding-top: 15px;
    font-size: 1rem;
    font-family: 'Open-Sans-Bold';
}